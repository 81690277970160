$animation-delay = 0.08


.loader-wrapper
  position: fixed
  display: block
  width: 100%
  height: 100%
  top: 0
  left: 0
  z-index: 99999

.loader
  left: 50%
  top: 50%
  height: 60px
  width: 60px
  position: fixed
  transform: translateX(-50%) translateY(-50%)


  span
    animation: loader 4s ease-in-out infinite
    position: absolute
    background: white
    display: block
    opacity: 0
    height: 12px
    width: 12px

    for $i in (1..16)
      &.loader-block:nth-child(16n + {$i})
        animation-delay: ((16 * $animation-delay) - ($i * $animation-delay))s

        top: (16px * floor($i / 4.01))
        if $i <= 4
          left: (16px * ($i - 1))
        else if $i <= 8
          left: (16px * ($i - 5))
        else if $i <= 12
          left: (16px * ($i - 9))
        else if $i <= 16
          left: (16px * ($i - 13))

@keyframes loader
  0%
    opacity: 0
    transform: translateY(-100px)

  15%
    opacity: 0
    transform: translateY(-100px)

  30%
    opacity: 1
    transform: translateY(0)

  70%
    opacity: 1
    transform: translateY(0)

  85%
    opacity: 0
    transform: translateY(100px)

  100%
    opacity: 0
    transform: translateY(100px)
